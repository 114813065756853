import { graphql } from 'gatsby'
// import orderBy from 'lodash/orderBy'
import React from 'react'
import Layout from '../components/Layout'
import NewsArticles from '../components/NewsArticles'
import SEO from '../components/SEO'

const CategoryTemplate = ({ data }) => {
  const categories = data.allContentfulCategory.edges
  const posts = data.contentfulCategory.post
    .concat()
    .sort((a, b) =>
      a.publishDateISO > b.publishDateISO
        ? -1
        : b.publishDateISO > a.publishDateISO
        ? 1
        : 0
    )
  const HeroImage = data.allContentfulAsset.edges[0].node

  return (
    <Layout pageClass="page--news">
      <SEO />
      <NewsArticles image={HeroImage} categories={categories} posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allContentfulCategory {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    contentfulCategory(slug: { eq: $slug }) {
      title
      id
      slug
      post {
        id
        title
        slug
        category {
          title
        }
        publishDate(formatString: "MMMM DD, YYYY")
        publishDateISO: publishDate(formatString: "YYYY-MM-DD")
        heroImage {
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    allContentfulPost {
      edges {
        node {
          category {
            title
          }
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          ...HeroImageSettings
        }
      }
    }
  }
`

export default CategoryTemplate
